// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

/* You can add global styles to this file, and also import other style files */

@use 'fonts';

@import 'variables';
@import 'flags32';

@import 'bootstrap/scss/bootstrap';
@import 'bootswatch/dist/materia/bootswatch';

@import 'd3-charts';
@import 'ag-grid';

@include fonts.twk-lausanne-font-faces;

@tailwind base;
@tailwind components;
@tailwind utilities;

:root,
:root * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html,
body {
  position: relative;
  height: 100%;
  color: $text-color;
  scroll-behavior: smooth;
  background: #ffffff;
  font-family: Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
}

body {
  margin: 0;
}

//
//TEXT DISPLAY
//

.t-data {
  font-family: 'TWK Lausanne', Helvetica, sans-serif;
}

//TODO: mby come up with a better name?
.t-data-alt {
  font-family: 'Roboto Mono', monospace;
}

//TODO: mby come up with a better name?
.t-data-label {
  font-family: 'SF Pro', Helvetica, sans-serif;
  text-transform: uppercase;
}

p {
  font-size: 1rem;
  line-height: 1.2;
  margin: 0;

  &.subtitle {
    color: $text-color-tertiary;
    font-weight: normal;
  }
}

.text-col {
  max-width: 615px;
}

a {
  color: $primary-color;
  // (Bootstrap Override)
  &:hover {
    color: $primary-color;
  }
}

dl {
  font-size: 1rem;

  &.horizontal {
    display: grid;
    grid-template-rows: auto auto;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    column-gap: 3rem;
  }

  &.flat {
    display: flex;
    @extend .d-flex;
    @extend .gap-1;
    dt,
    dd {
      margin-bottom: 0;
    }

    dd:not(:last-child) {
      margin-right: 1rem;
    }
  }

  dt {
    color: $text-color-secondary;
    font-weight: normal; //(Bootstrap Override)
    margin-bottom: 0.9rem;
  }
  &:not(.horizontal):not(.flat) dd:not(:last-child) {
    margin-bottom: 2rem;
  }
  &:not(.t-normal) dd {
    font-weight: bold;
  }
}

// Originally the style for <p> and .text-standard is made identical (commit 50408be). But on the public website, the font-size, letter-spacing is too small
// when compared to Sayaka's design spec.
.text-standard {
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 0.1px;
}

//
// BASE COMPONENTS
//

//BUTTONS
button:focus {
  outline: 0;
}

.btn {
  border-radius: 5px;
  text-transform: none;
  box-shadow: none;
  font-size: 0.875rem;
  white-space: nowrap;
}

.btn,
.btn-primary,
.btn-link {
  font-weight: bold;
}

.btn-primary {
  background: $primary-color;
  border: solid $primary-color 1px;
  &:focus,
  &:active {
    background: $primary-color !important;
  }
  &:hover:not(:disabled) {
    color: $primary-color;
    background: $primary-color-lightest !important;
    border: solid $primary-color-lightest 1px !important;
  }
}

.btn-outline {
  color: $primary-color;
  border: solid currentColor 1px;
  background: none;

  &:hover {
    color: $primary-color;
    background: $light-gray-opacity;
  }
}

.btn-yellow {
  background: $yellow;
  color: $dark;

  &:hover {
    filter: brightness(90%);
    color: $primary;
  }
}

.btn-refinitiv-blue {
  background: $refinitiv-blue;
  color: $white;

  &:hover {
    filter: brightness(90%);
    color: $white;
  }
}
// (Bootstrap Override)
.btn-link {
  color: $primary-color;
  &:hover {
    color: $primary-color;
  }
}

//TODO: Possibly remove this as it looks like the class has been removed
.btn-clear {
  border: solid $white;
  background: none;
  color: $white;

  &:hover {
    background: $whitesmoke;
    color: $primary;
  }
}

//BLOCK / CARD
.rounded {
  border-radius: 0.25rem;
}
.rounded-1 {
  border-radius: 0.25rem;
}
.rounded-2 {
  border-radius: 0.5rem;
}
.rounded-3 {
  border-radius: 0.75rem;
}
.rounded-4 {
  border-radius: 1rem;
}

.block {
  display: block;
  padding: 1.25rem;
  background: white;
  border: solid 1px rgba(0, 0, 0, 0.125);
  @extend .rounded-1;

  &.shadow {
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.1) !important;
  }

  .section {
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px;
  }

  .section:first-child {
    padding-top: 0px;
  }

  .section:last-child {
    padding-bottom: 0px;
  }

  .section:not(:first-child) {
    border-top: solid 1px #eee;
  }
}

.green-card {
  @extend .card;
  background-color: $lightest-green;
  border: 1px solid darken($lightest-green, 10);
}

.card {
  box-shadow: none;
}

// (Bootstrap Override)
.card-footer {
  background: transparent;
  &:has(a) {
    padding: 0;
  }
  a {
    padding: 0.75rem 1.25rem;
    width: 100%;
  }
}

//FORMS
.form-group {
  margin-bottom: 3rem;
}

.alert {
  padding: 0;
  margin-bottom: 0;
  @extend .t-smaller;
  //Need this to override p style
  * {
    font-size: inherit;
  }
}

//INPUTS
.dropdown {
  .dropdown-toggle {
    &::after {
      display: none !important;
    }
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background: $primary;
  }
}

textarea.border {
  padding: 20px;
}

select,
select.form-control {
  padding-right: 0.75rem;

  &.t-bold {
    color: $primary-color;
    font-weight: bold;
  }
}

.form-group label:has(+ .form-control:disabled) {
  color: $text-color-tertiary;
}

.form-control {
  color: $text-color;
}

//FILTERS
.fs-filters {
  select,
  select.form-control,
  input {
    color: $primary-color;
    font-weight: bold;
  }
}

//TABLES
table:not(.mat-calendar-table) {
  tbody {
    border: solid 1px $whitesmoke !important;
  }

  tfoot {
    background-color: rgba($lightgray, 0.15) !important;
  }

  th {
    font-weight: normal;
  }
}

.dataTables_filter {
  label {
    width: 100%;

    input {
      width: 100% !important;
      padding: 1rem 0;
    }
  }
}

.dt-buttons::before {
  content: 'DOWNLOAD';
  padding-right: 1em;
}
.pagination {
  color: $primary-color;
  .page-item.active .page-link {
    color: currentColor;
    background: none;
    border-color: $primary-color;
  }
}

//
// HELPER CLASSES
//
//LAYOUT
//Extend base class with default
.d-flex {
  display: flex;
  align-items: center;
}
// Will be replaced with a proper grid based layout library
.d-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr;

  &.columns-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &.columns-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  .row-span-2 {
    grid-row: span 2;
  }

  .col-span-2 {
    grid-column: span 2;
  }
}

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

// Classes of {class}-0 => {class}-5
@each $i, $value in $spacers {
  .gap-#{$i} {
    gap: $value;
  }
  .column-gap-#{$i} {
    column-gap: $value;
  }
  .row-gap-#{$i} {
    row-gap: $value;
  }
}

.flex-b-100 {
  flex-basis: 100%;
}

.flex-s-2 {
  flex-shrink: 2;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.clear-both {
  clear: both;
}

//COLOURS
.bg-whitesmoke {
  background: $whitesmoke !important;
}

.bg-lightgray-soft {
  background-color: rgba($lightgray, 0.15);
}

.transparent-white {
  color: rgba(255, 255, 255, 0.5);
}

.transparent-black {
  color: rgba(0, 0, 0, 0.5);
}

.c-light {
  color: $text-color-secondary;
}

.c-lightest {
  color: $text-color-tertiary;
}

.c-dark {
  color: $dark;
}

.c-dark-green {
  color: $dark-green;
}

.c-darkest-green {
  color: $darkest-green;
}

.c-green {
  color: $green;
}

//TEXT DISPLAY
.text-preserve-lines {
  white-space: pre-line;
}

//TEXT DISPLAY
.text-preserve-lines {
  white-space: pre-line;
}

.t-largest {
  font-size: 1.953rem; //31px
}
.t-larger {
  font-size: 1.563rem; //25px
}
.t-large {
  font-size: 1.25rem; //20px
}
.t-normal {
  font-weight: normal; //16px
  font-size: 1rem;
}
.t-small {
  font-size: 0.875rem; //14px
}
.t-smaller {
  font-size: 0.75rem; //12px
}

// h1, h2, h3, h4, h5, h6 {}
$heading-sizes: 't-largest' 't-larger' 't-large' 't-normal' 't-small' 't-smaller';
@each $size in $heading-sizes {
  $i: index($heading-sizes, $size);
  h#{$i} {
    @extend .#{$size};
    font-weight: 500;
    font-family: 'TWK Lausanne', Helvetica, sans-serif;
  }
}
h4 {
  font-weight: 300;
}

.text-dark {
  color: $text-dark;
}

//VISUAL STYLE
.no-shadow {
  box-shadow: none !important;
}

//INTERACTION
.no-pointer {
  cursor: default !important;
}
.pointer {
  cursor: pointer !important;
}

//
// FUNCTION BASED COMPONENTS
//

//TODO: Unsure if we use this anymore
.carousel {
  .carousel-control-prev,
  .carousel-control-next {
    background: $lightgray;
    width: 30px;
  }

  &:not(.show-controls) {
    .carousel-control-prev,
    .carousel-control-next {
      display: none;
    }

    &:hover {
      .carousel-control-prev,
      .carousel-control-next {
        display: flex;
      }
    }
  }
}

//TODO: Unsure if we use this anymore
.item {
  .heading {
    @extend .heading;
    font-size: 20px;
  }

  .heading-yellow {
    @extend .heading;
    font-size: 18px;
    letter-spacing: 1px;
    color: $yellow;
  }

  .description {
    @extend .text-standard;
    margin-top: 10px;
  }
}

//TODO: Move to usage
.nav-tabs .nav-link {
  background-color: transparent !important;
  padding-left: 2rem;
  padding-right: 2rem;
  border: none;

  &[disabled] {
    color: $lightgray;
    &:hover {
      box-shadow: none !important;
    }
  }
  &:hover,
  &:focus:hover {
    color: $primary;
    box-shadow: inset 0 -4px 0 $primary !important;
  }

  h3 {
    font-size: 1.5em;
  }
}

.help {
  &::after {
    content: ' ';
    display: block;
    background-image: url('/assets/images/icons/questionmark.svg');
    background-size: 1em;
    width: 1em;
    height: 1em;
    float: left;
    margin-right: 0.5em; // Amount may need to change
    cursor: pointer;
  }

  &.right::after {
    float: right;
    margin-left: 0.5em;
    margin-right: 0;
  }
}

.flag {
  height: 32px;
  padding-left: 40px;
}

.modal {
  .modal-dialog-80 {
    max-width: 80%;
  }
}

.modal-dialog {
  max-width: 650px;

  .modal-header {
    margin-bottom: 0;
  }

  .modal-content {
    border-radius: 20px;
  }
}

// Move the modal dialog to the right, like a side bar
.modal-dialog[class*='right'] {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  align-items: normal;
  margin: 0;
}

.modal-dialog[class*='right'] .modal-content {
  overflow: scroll;
  border-radius: 0;
}

// Default width 60%
.modal-dialog.right {
  width: 60%;
}
// Custom 80% width
.modal-dialog.right-80 {
  width: 80%;
}
// Fixed width to 1000px
.modal-dialog.right-1000px {
  width: 1000px;
}

//TODO: Could move to a directive to not have to have each place define their max-height
.hideable {
  transition:
    max-height,
    padding,
    margin,
    border,
    box-shadow,
    0.5s ease-in-out;
  overflow: hidden;
  &.hide {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    max-height: 0 !important;
    box-shadow: none !important;
    border-width: 0 !important;
  }
}

.adjustedRiskRating.tooltip {
  .tooltip-inner {
    max-width: 500px;
    ul {
      text-align: left;
      margin: 0px;
      padding: 0px 5px 0px 25px;
      li {
        padding: 1px;
      }
    }
  }
}

// style for highlighting new beta features from hackathon.
//TODO: Move to a component
.beta {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 30px;
  font-weight: bold;
  background: $yellow;
  color: $dark;
  border-radius: 5px;
  user-select: none;
}

.underlined {
  display: inline-block;
  position: relative;
  padding-bottom: 5px;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: currentColor;
  }
}

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pending {
  color: var(--fsn-color-text-light);
}
