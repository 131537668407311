@use 'sass:map';
@use '@angular/material' as mat;
@use 'fs-next/theming/palette';
@import 'variables';

@mixin color($theme) {
  :root {
    --fsn-comp-d3-bar-chart-bar-color: #{$primary};
    --fsn-comp-d3-bar-chart-highlightedbar-color: #{$yellow};
    --fsn-comp-d3-bar-chart-hover-color: #{$yellow};
    --fsn-comp-d3-bar-chart-label-color: #{primary};
  }

  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    &.fs-next {
      --fsn-comp-d3-bar-chart-bar-color: #{mat.get-theme-color($theme, accent, 700)};
      --fsn-comp-d3-bar-chart-highlightedbar-color: #{mat.get-theme-color($theme, accent, 500)};
      --fsn-comp-d3-bar-chart-hover-color: #{mat.get-theme-color($theme, accent, 500)};
      --fsn-comp-d3-bar-chart-label-color: var(--fsn-color-input-text);
    }
  }
}
@mixin theme($theme) {
  @include color($theme);
}
